import { ActionIcon, Box, Button, Divider, Grid, Group, LoadingOverlay, Menu, Modal, Paper, Progress, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { FaArchive, FaCalendar, FaChalkboard, FaEdit, FaEllipsisV, FaEye, FaPause, FaPlus, FaTrash, FaUser } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomerAvatar from '../components/customer_avatar';
import SimpleHeader from '../components/simple_header';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_ALL_CUSTOMERS } from '../services/customers';
import { ARCHIVE_JOB, DELETE_JOB, GET_ALL_JOBS } from '../services/jobs';
import { getExtenseDatetime, useQuery } from '../utility/util';
import { JobsCalendar } from '../components/jobs_calendar';

export default function JobsPage(){
    const [items, setItems] = React.useState<any[]>([]);
    const [customers, setCustomers] = React.useState<any[]>([]);
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const [openDone, setOpenDone] = React.useState(false);
    const [openCustomer, setOpenCustomer] = React.useState(false);

    const query = useQuery();
    const customerId = query.get("customer");

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const app = useApp();
    const { role } = useAuth();
    const viewType = query.get("present");

    const selectedCustomer = customers.find(c => customerId === c._id)

    const loadItems = () => {
        setLoadingItems(true)
        GET_ALL_JOBS()
        .then(res => {
            setItems(res);
            setLoadingItems(false)
        })
        .catch(err => {
            setLoadingItems(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const loadCustomers = () => {
        if(role){
            GET_ALL_CUSTOMERS()
            .then(res => {
                setCustomers(res)
            })
            .catch(err => {
                showNotification({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }
    
    const archiveJob = (jobId, archive = true) => {
        let previous = [...items];
        setItems(i => i.map(j => j.id === jobId ? {...j, archived: archive} : j))
        ARCHIVE_JOB(jobId, archive)
        .then(res => {
            showNotification({message: "O job foi arquivado", color: "green"});
        })
        .catch(err => {
            setItems(previous);
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const deleteJob = (jobId) => {
        let previous = [...items];
        setItems(i => i.filter(j => j.id !== jobId))
        DELETE_JOB(jobId)
        .then(res => {
            showNotification({message: "O job foi deletado", color: "green"});
        })
        .catch(err => {
            setItems(previous);
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const renderItem = (item, index) => {
        
        return <Paper key={`${item.id}`} p="md" mb="xs" shadow='xs'>
            <UnstyledButton
                style={{width: "100%"}}
                onClick={() => {
                    navigate(role ? `/jobs/${item.id}` : `/jobs/${item.id}`)
                }}
            >
                <Box pb="xs">
                    <Text size="md" weight="bold">{item.name}</Text>
                    <Text size="sm" color="gray">{item?.customer?.name}</Text>
                </Box>
                {item.deadline && <Group spacing={2} mb="md" position='right'>
                    <Text size="xs" color="#BABABA">{getExtenseDatetime(item.deadline)}</Text>
                    <FaCalendar color="#BABABA" />
                </Group>}
                {item.statusKey !== "approved" && (item?.customer?.flow ?? [])
                .map((flow, flowIndex) => {
                    const qtToApprove = item.tasks.filter((t) => [null, undefined, "draft", "to-approve"].includes(t.job_status.filter(js => (js.index ?? 0) === flowIndex).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status?.key) ).length;
                    const qtAdjust = item.tasks.filter((t) => ["adjust"].includes(t.job_status.filter(js => (js.index ?? 0) === flowIndex).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status?.key) ).length;
                    const qtApproved = item.tasks.filter((t) => ["approved"].includes(t.job_status.filter(js => (js.index ?? 0) === flowIndex).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status?.key) ).length;
                    
                    return <Progress
                        mb="xs"
                        size="xl"
                        sections={[
                            { label: "Aguardando aprovação", value: (item.tasks || []).length === 0
                                ? 0
                                : (qtToApprove * 100) / (item.tasks || []).length, color: "gray",
                                tooltip: `${qtToApprove} - Aguardando aprovação`},
                            { label: "Em Ajuste", value: (item.tasks || []).length === 0
                                ? 0
                                : (qtAdjust * 100) / (item.tasks || []).length, color: "yellow",
                                tooltip: `${qtAdjust} - Em Ajuste`},
                            { label: `${flow.title ?? `Nível ${flowIndex + 1}`}`, value: (item.tasks || []).length === 0
                                ? 0
                                : (qtApproved * 100) / (item.tasks || []).length, color: "green",
                                tooltip: `${qtApproved} - Aprovados`},
                        ]}
                    />
                })}
            </UnstyledButton>
            <Group style={{justifyContent: 'space-between'}}>
                <Text color="lime" style={{flex: 1}} pl="xs" size="xs">{(item.tasks || []).length} itens</Text>
                {role && <Menu>
                    <Menu.Target>
                        <ActionIcon color="lime"><FaEllipsisV /></ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item color="lime" icon={<FaEye />} onClick={() => navigate(`/jobs/${item.id}`)}>Visualizar</Menu.Item>
                        <Menu.Item color="lime" icon={<FaEdit />} onClick={() => navigate(`/jobs/${item.id}?u=true`)}>Editar</Menu.Item>
                        <Menu.Item color="yellow" icon={<FaArchive />} onClick={() => {
                            app.confirmDialog({text: `Realmente deseja ${item.archived ? "desarquivar" : "arquivar"} o job?`}, ({confirmed}) => {
                                if(confirmed) archiveJob(item.id, !item.archived);
                            })
                        }}>{item.archived ? "Desarquivar" : "Arquivar"}</Menu.Item>
                        <Menu.Item color="red" icon={<FaTrash />} onClick={() => {
                            app.confirmDialog({text: "Realmente deseja deletar o job?"}, ({confirmed}) => {
                                if(confirmed) deleteJob(item.id);
                            })
                        }}>Deletar</Menu.Item>
                    </Menu.Dropdown>
                </Menu>}
            </Group>
        </Paper>
    }

    const getItems = (status, archived = false) => (
        items
        .filter(i => 
            (archived ? !!i.archived : !i.archived) &&
            (!customerId || i.customer?._id === customerId) &&
            (!status || i.statusKey === status)
        )
        .sort((a,b) => a.last_update > b.last_update ? -1 : 1)
    )

    useEffect(loadItems, []);
    useEffect(loadCustomers, [role]);

    return <>
        <SimpleHeader
            title="Jobs"
            right={
                <>
                    <Tooltip label="Alterar Visualização">
                        <ActionIcon
                            size="xl"
                            variant="light"
                            color='gray'
                            onClick={() => {
                                searchParams.set("present", viewType === "calendar" ? "kanbam" : "calendar");
                                setSearchParams(searchParams);
                            }}
                        >
                            {viewType === "calendar" ? <FaCalendar /> : <FaPause />}
                        </ActionIcon>
                    </Tooltip>
                    {role && <Menu position='bottom' onOpen={() => setOpenCustomer(true)} closeOnEscape opened={openCustomer} onClose={() => setOpenCustomer(false)}>
                        <Menu.Target>
                            {selectedCustomer
                                ? <UnstyledButton><CustomerAvatar size="md" color="white" name={selectedCustomer.name} image={selectedCustomer.image} /></UnstyledButton>
                                : <Button size="md" variant='white' leftIcon={<FaUser/>}>Filtrar cliente</Button>}
                        </Menu.Target>
                        <Menu.Dropdown style={{maxWidth: 300, minWidth: 300}}>
                            <Menu.Item onClick={() => {
                                setOpenCustomer(false);
                                searchParams.delete("customer");
                                setSearchParams(searchParams);
                            }}>Ver Todos</Menu.Item>
                            <Menu.Divider></Menu.Divider>
                            {customers
                            .filter(c => c._id !== customerId)
                            .map(customer =>
                                <UnstyledButton
                                    style={{width: "100%"}}
                                    onClick={() => {
                                        searchParams.set("customer", customer._id);
                                        setSearchParams(searchParams);
                                        setOpenCustomer(false)
                                    }}
                                    p="sm"
                                >
                                    <CustomerAvatar
                                        name={customer.name}
                                        image={customer.image}
                                    />
                                </UnstyledButton>
                            )}
                        </Menu.Dropdown>
                    </Menu>}
                </>
            }
            buttons={[
                role && { title: "Arquivados", color: "gray", leftIcon: <Text color="white" weight="bold">({getItems(null, true).length})</Text>, onClick: () => setOpenDone(true) },
                role
                ? { title: "Job", leftIcon: <FaPlus />, onClick: () => {navigate("/jobs/novo")} }
                : { title: "Job", leftIcon: <FaPlus />, onClick: () => {navigate("/solicitar")} }
            ].filter(nn => nn)}  
        />

        <LoadingOverlay visible={loadingItems} />
        
        {
            viewType === "calendar"
            ? <JobsCalendar
                jobs={items}
            />
            : <>
                <Grid>
                    <Grid.Col xs={6} md={3} style={{padding: 2}}>
                        <Paper p="md" shadow='xs'>
                            <Title order={4} align='center'>({getItems("draft").length}) {role ? "Rascunho" : "Solicitações"}</Title>
                            <Divider mt="xs" mb="xs" />
                            {getItems("draft").map(renderItem)}
                        </Paper>
                    </Grid.Col>
                    <Grid.Col xs={6} md={3} style={{padding: 2}}>
                        <Paper p="md" shadow='xs'>
                            <Title order={4} align='center'>({getItems("to-approve").length}) Aguardando aprovação</Title>
                            <Divider mt="xs" mb="xs" />
                            {getItems("to-approve").map(renderItem)}
                        </Paper>
                    </Grid.Col>
                    <Grid.Col xs={6} md={3} style={{padding: 2}}>
                        <Paper p="md" shadow='xs'>
                            <Title order={4} align='center'>({getItems("adjust").length}) Pedido de ajuste</Title>
                            <Divider mt="xs" mb="xs" />
                            {getItems("adjust").map(renderItem)}
                        </Paper>
                    </Grid.Col>
                    <Grid.Col xs={6} md={3} style={{padding: 2}}>
                        <Paper p="md" shadow='xs'>
                            <Title order={4} align='center'>Aprovados</Title>
                            <Divider mt="xs" mb="xs" />
                            {getItems("approved").map(renderItem)}
                        </Paper>
                    </Grid.Col>
                </Grid>
            </>
        }
        {/* <Table
            columns={[
                { title: "Cliente", key: "customer", render: item => <CustomerAvatar name={item?.customer?.name} /> },
                { title: "Nome do JOB", key: "name" },
                { title: "Itens", key: "tasks", render: item => (item.tasks || []).map(t => <div>{t.name}</div>) },
                { title: "Última atualização", key: "last_update", render: item => moment(item.last_update).format("DD/MM/YYYY HH:mm:ss") },
                { title: "Status", key: "status", render: item => <Badge color={item.statusCategory === "done" ? "green" : item.statusCategory === "pendent" ? "gray" : "blue"}>{item.status}</Badge> },
            ]}
            actions={[
                {
                    title: "Editar",
                    color: "blue",
                    onClick: ({item}) => {
                        navigate(role ? `/jobs/${item.id}` : `/j/${item.id}`)
                    }
                },
                {
                    title: "Deletar",
                    color: 'red',
                    onClick: ({item}) => {
                        app.confirmDialog(
                            {text: "Realmente deseja deletar?"},
                            (({confirmed}) => {
                                if(confirmed) deleteItem(item.id);
                            })
                        )
                    }
                },
            ]}
            data={items}
        /> */}
        
        <Modal
            opened={openDone}
            onClose={() => { setOpenDone(false) }}
            size="sm"
        >
            {getItems(null, true).map(renderItem)}
        </Modal>
    </>
}