import { LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, {
  createContext,
  useCallback,
  useContext, useEffect, useLayoutEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { AUTH_LOGIN, COMPANY_BY_HOST } from "../services/auth";
import { CHANGE_USER_STATUS, GET_SELF_PROFILE, GET_USER_DATA } from "../services/users";
import { RouteProps } from "../utility/routes";

interface AuthContextProps {
  user: any;
  userData: any;
  planData: any;
  role: any;
  onChangeRole: (roleId : number, refresh? : boolean) => void;
  companyHost: any;
  roles: any[];
  teams: any[];
  hasPermission: (route : RouteProps) => boolean;
  changeStatus: (status: string) => void;
  logout: () => void;
  loadUserData: () => void;
  login: (
    email: string,
    password: string,
    callback?: () => void
  ) => Promise<void>;
  signIn: (
    user : any,
    callback?: () => void
  ) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const localRole = localStorage.getItem("@aprovaai/role");
  const lsUser = localStorage.getItem("@aprovaai/user");
  const [isLoading, setIsLoading] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [loadingCompanyByHost, setLoadingCompanyByHost] = useState(true);
  const [companyHost, setCompanyHost] = useState(null);

  const [user, setUser] = useState(lsUser ? JSON.parse(lsUser) : null);
  const [role, setRole] = useState(localRole ? JSON.parse(localRole) : null);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  const loadUserData = async () => {
    try{
      setIsLoading(true);
      setUserData(await GET_USER_DATA());
    }catch(err){
      if(err.message === "Unauthorized"){
        localStorage.removeItem("@aprovaai/role");
        localStorage.removeItem("@aprovaai/user");
        window.location.href = "/login"
      }else{
        setUserData(null);
        setRole(null);
      }
    }finally{
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setLoadingCompanyByHost(true)
    COMPANY_BY_HOST()
    .then(res => {
      setCompanyHost(res)
      setLoadingCompanyByHost(false)
    })
    .catch(err => {
      setLoadingCompanyByHost(false)
    })
  }, [])

  useEffect(() => {
    if(user) loadUserData();
  }, [user])

  useEffect(() => {
    if(!role && userData?.user?.role){
      setRole(userData.user.role);
    }
  }, [userData, role])

  useEffect(() => {
    if(role) localStorage.setItem("@aprovaai/role", JSON.stringify(role));
    else setRole(null);
  }, [role])

  const signIn = async (user, callback) => {
    localStorage.setItem("@aprovaai/user", JSON.stringify(user));
    setUser(user);
    callback();
  }

  const login = useCallback(
    async (email: string, password: string, callback?: () => void) => {
      try {
        const {user} = (await AUTH_LOGIN({ email, password }));
        signIn(user, callback);
      } catch (err) {
        showNotification({
          color: "red",
          title: "Erro!",
          message: err.message,
        });
        console.error({ err });
      }
    },
    []
  );

  const hasPermission = (route : RouteProps) => {
    return true
    return !route.private
      || (
        userData && 
        (userData.resources || []).some(r => r.key === route.key)
      )
  }

  const logout = () => {
    localStorage.removeItem('@aprovaai/user');
    localStorage.removeItem('@aprovaai/role');
    setUser(null)
    setRole(null)
  }

  const onChangeRole = (roleId, refresh = true) => {
    // if(refresh){
      setShowChildren(false);
      setTimeout(() => setShowChildren(true), 10);
    // }
    const role = (userData?.user?.roles || []).find(r => `${r._id}` === `${roleId}`);
    setRole(role);
    loadUserData();
  }

  const planData = userData?.plan;

  const changeStatus = (status) => {
    CHANGE_USER_STATUS(status)
    .then(() => {})
    .catch(() => {})
  }

  useEffect(() => {
    if(user){
      return () => { changeStatus("inactive") }
    }
  }, [user])

  const value = useMemo<AuthContextProps>(() => {
    return {
      user,
      userData: userData,
      onChangeRole,
      planData,
      companyHost,
      loadUserData,
      role,
      teams: userData?.user?.teams || [],
      roles: (userData?.user?.roles || []),
      changeStatus,
      signIn, login, hasPermission, logout
    };
  }, [user, role, userData, planData, login, signIn, hasPermission]);

  const someLoading = isLoading || loadingCompanyByHost;

  return (
    <AuthContext.Provider value={value}>
      <LoadingOverlay visible={someLoading} />
      {!someLoading && showChildren && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
