import { api } from "./api"

export const AUTH_LOGIN = async (params : {email:string, password:string}) => {
    const {data} = await api.post("/auth/login", params)
    return data;
}

export const SEARCH_COUPON = async (code) => {
    const {data} = await api.get(`/auth/coupon/by/code/${code}`)
    return data;
}

export const COMPANY_BY_HOST = async () => {
    const {data} = await api.get("/auth/company/by/host")
    return data;
}

export const GET_PUBLIC_LINK_DATA = async (key) => {
    const {data} = await api.get(`/auth/public/link/${key}`)
    return data;
}

export const FORGOT_PASSWORD = async (email: string) => {
    const {data} = await api.post("/auth/forgot/password", {email})
    return data;
}

export const AUTH_GET_INVITE = async (params : {key:string}) => {
    const {data} = await api.get(`/auth/invite/${params.key}`)
    return data;
}

export const AUTH_CONFIRM_INVITE = async (params : {key:string;password:string}) => {
    const {data} = await api.post(`/auth/invite/${params.key}`, {password: params.password})
    return data;
}

export const SAVE_NOTIFICATION_TOKEN = async (token) => {
    const {data} = await api.patch(`/users/notification/token`, { token })
    return data;
}
