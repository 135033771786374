import {
  Box,
  Button,
  Center, Divider, Group, Modal, Paper, PasswordInput,
  Space,
  Text,
  TextInput,
  Title,
  UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import InputField from "../components/input_field";
import { FORGOT_PASSWORD } from "../services/auth";
import { showNotification } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";

export default function Login(){
  const { login, companyHost, user } = useAuth();
  const { configs } = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [email, setEmail] = useState("");
  const [forgot, setForgot] = useState(null);
  const [password, setPassword] = useState("");

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";

  const confirmForgot = e => {
    e.preventDefault();
    const email = forgot.email;
    FORGOT_PASSWORD(email)
    .then(() => {
      setForgot(null);
      showNotification({title: "Recuperação de senha", message: `Foi enviado um e-mail para ${email} com instruções para recuperar sua senha`})

    })
    .catch(err => {
      showNotification({title: "Ops", message: err.message, color: 'red'})
    }) 
  }

  return !!user
  ? <Navigate to="/" state={{ from }} />
  : (
    <Center style={{ width: "100vw", minHeight: "100vh", background: `linear-gradient(to top right, ${configs.navbar ?? "#FFFFFF"}, ${configs.navbar ?? "#FFFFFF"}, ${configs.navbar ?? "#FFFFFF"})` }}>
      <div
        style={{
          width: isMobile ? '90vw' : "40vw",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 80,
        }}
      >
        <div style={{display: 'flex', marginBottom: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <img alt="AprovaAi" style={{height: 80, width: "60%", objectFit: 'contain'}} src={configs.image ?? "/logo.png"}/>
        </div>
        <Paper shadow="xs" p="xl" >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              login(email, password, () => {
                navigate(from, { replace: true });
              });
            }}
          >
            <Box>
              <Title align="center" order={2}>Entrar</Title>
              <Group mt="sm" style={{justifyContent: 'center'}}>
                <Title order={5} color="gray">Ainda não tem uma conta?</Title>
                <Link to={companyHost ? "/cadastrar/cliente" : "/cadastrar"}><Text color="lime">Criar conta</Text></Link>
              </Group>
            </Box>
            <TextInput
              mt="md"
              label="E-mail"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              />
            <PasswordInput
              mt="md"
              label="Senha"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <UnstyledButton mt="md" onClick={() => setForgot({email: ""})} style={{width: '100%'}}>
              <Text underline size="sm" color="lime" align="right">Esqueci minha senha</Text>
            </UnstyledButton>
            <Button type="submit" mt="xl" size="md" fullWidth>
              Entrar
            </Button>
            <Group mt="md" align="center" position="center">
              <Text size="xs" ta="center" style={{cursor: 'pointer'}} color="gray" onClick={() => window.open("https://aprova.ai/privacy-policy/", "_blank")}>Políticas de Privacidade</Text>
              <Divider orientation="vertical" />
              <Text size="xs" ta="center" style={{cursor: 'pointer'}} color="gray" onClick={() => window.open("https://aprova.ai/terms-of-use/", "_blank")}>Termos de Uso</Text>
            </Group>
          </form>
        </Paper>
      </div>

      <Modal size="sm" opened={!!forgot} onClose={() => setForgot(null)}>
        <form onSubmit={confirmForgot}>
          <InputField name="email" placeholder="Digite seu e-mail" title="E-mail" value={forgot?.email} onChange={({email}) => setForgot(f => ({...f, email}))} />
          
          <Group position="right" mt="lg">
            <Button color="lime" variant="light" type="button" mr="xs" onClick={() => setForgot(null)}>Cancelar</Button>
            <Button color="lime" variant="filled" type="submit">Recuperar Senha</Button>
          </Group>
        </form>
      </Modal>
    </Center>
  );
};
