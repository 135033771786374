import { api } from "./api"

export const SEND_INVITE = async (formData : any) => {
    const {data} = await api.post("/companies/send/invite", formData)
    return data;
}

export const GET_ALL_INVITES = async () => {
    const {data} = await api.get("/companies/invites")
    return data.invites || [];
}

export const UPDATE_SELF_COMPANY = async (params) => {
    const {data} = await api.patch("/companies/self", params)
    return data;
}

export const UPDATE_COMPANY = async (id, params) => {
    const {data} = await api.patch(`/companies/${id}/update`, params)
    return data;
}

export const UPDATE_COMPANY_PREFERENCES = async (params) => {
    const {data} = await api.patch("/companies/preferences", params)
    return data;
}

export const GET_COMPANY_PREFERENCES = async () => {
    const {data} = await api.get("/companies/preferences")
    return data;
}

export const GET_COMPANY_INTEGRATION = async (key: string) => {
    const {data} = await api.get(`/companies/integrations/${key}`)
    return data;
}

export const SET_COMPANY_INTEGRATION = async (key: string, params: any, generates: {key: string}[] = []) => {
    const {data} = await api.post(`/companies/integrations/${key}`, {...params, generates})
    return data;
}

export const SAVE_COMPANY_DOMAIN = async (params: any) => {
    const {data} = await api.post("/companies/domains", params)
    return data;
}

export const GET_COMPANY_DOMAINS = async () => {
    const {data} = await api.get("/companies/domains")
    return data;
}

export const SAVE_COMPANY_PLAN = async (params: any) => {
    const {data} = await api.post("/companies/plans/start", params)
    return data;
}

export const CONNECT_COMPANY_OAUTH = async (media, params) => {
    const {data} = await api.patch(`/companies/oauth/${media}`, params)
    return data;
}

export const CONSULT_LABEL_AVAILABILITY = async (label) => {
    const {data} = await api.get("/companies/label/availability", { params: { label } })
    return data;
}

export const GET_COMPANY_ONBOARDING_DATA = async () => {
    const {data} = await api.get("/companies/onboarding")
    return data;
}

export const SAVE_COMPANY_ONBOARDING_DATA = async (params) => {
    const {data} = await api.post("/companies/onboarding", params)
    return data;
}